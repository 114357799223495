import React from "react";
import clsx from "clsx";

import { Carousel, Slide } from "@molecules";
import { Container } from "@atoms";

const TestimonialCarousel = ({ slides, className: _className }) => {
  return (
    <section className={clsx("my-10 sm:my-20 lg:mt-32", _className)}>
      <Container>
        <Carousel maxVisible={1} indicators>
          {slides?.map(slide => {
            return <Slide {...slide} />;
          })}
        </Carousel>
      </Container>
    </section>
  );
};

export default TestimonialCarousel;
